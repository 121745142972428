<script setup lang="ts">
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import AccountSettingsViewContent from '@/account/components/AccountSettingsViewContent.vue';
    import { useAuthenticationAction, useAuthenticationQuery } from '@/composables/authentication';
    import { useCompanyAction } from '@/composables/company';
    import { Navigation } from '@/router/navigation';
    import { useAsyncTask } from 'vue-concurrency';
    import { RouterLink } from 'vue-router';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();
    const { isUserLoggedIn } = useAuthenticationQuery();
    const authenticationAction = useAuthenticationAction();
    const companyAction = useCompanyAction();

    const task = useAsyncTask(async () => {
        await authenticationAction.fetchCurrentCustomerIfMissing.perform();

        return await companyAction.findCustomerCompany();
    }).perform();
</script>

<template>
    <div v-if="isUserLoggedIn" class="flex-container">
        <AccountSettingsViewContent v-if="task.isSuccessful" :company="task.value ?? undefined" />
        <LoadingSpinner v-else-if="task.isRunning" />
    </div>
    <div v-else>
        {{ t('ACCOUNT.NOT_LOGGED_IN') }}
        <RouterLink :to="{ name: Navigation.Login }">{{ t('LOGIN.LOGIN') }}</RouterLink>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .flex-container {
        display: flex;
        row-gap: main.$spacing-6;
        flex-direction: column;
        background: main.$vt-c-white;
    }
</style>
