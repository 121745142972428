<script setup lang="ts">
    import { type CustomerDto, CustomerRole } from '@containex/portal-backend-dto';
    import { SalutationType } from '@/types/Salutation';
    import { useI18n } from 'vue-i18n';
    import { computed } from 'vue';

    const props = defineProps<{
        customer: CustomerDto;
    }>();

    const { t } = useI18n();

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const salutationDisplay = computed(() => salutationDisplayFor(props.customer.salutation as SalutationType));
    const roleDisplay = computed(() => props.customer.roles.map(roleDisplayFor).join(', '));

    function salutationDisplayFor(s: SalutationType): string {
        switch (s) {
            case SalutationType.MALE:
                return t('COMMON.MALE');
            case SalutationType.FEMALE:
                return t('COMMON.FEMALE');
        }
    }

    function roleDisplayFor(role: CustomerRole): string {
        switch (role) {
            case CustomerRole.ADMIN:
                return t('ACCOUNT.ROLE.ADMIN');
            case CustomerRole.CUSTOMER:
                return t('ACCOUNT.ROLE.CUSTOMER');
            case CustomerRole.CONSIGNEE:
                return t('ACCOUNT.ROLE.CONSIGNEE');
            case CustomerRole.INVOICE_RECIPIENT:
                return t('ACCOUNT.ROLE.INVOICE_RECIPIENT');
        }
    }
</script>

<template>
    <div>
        <div>{{ salutationDisplay }} {{ customer.first_name }} {{ customer.last_name }}</div>
        <div>{{ customer.job_title }}</div>
        <br />
        <div>{{ t('ACCOUNT.TELEPHONE') }}: {{ customer.phone_business }}</div>
        <div>{{ t('ACCOUNT.TELEPHONE_MOBILE') }}: {{ customer.phone_mobile }}</div>
        <br />
        <div>{{ t('ACCOUNT.ROLES') }}: {{ roleDisplay }}</div>
    </div>
</template>

<style scoped lang="scss"></style>
