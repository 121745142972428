<script setup lang="ts">
    import { object, string } from 'zod';
    import { useForm } from 'vee-validate';
    import { toTypedSchema } from '@vee-validate/zod';
    import FormField from '@/components/form-components/FormField.vue';
    import { hasValidationError } from '@/util/hasValidationError';
    import Button from 'primevue/button';
    import Dialog from 'primevue/dialog';
    import Password from 'primevue/password';
    import type { PasswordUpdateCredentials } from '@/model/password-update-credentials';
    import { useI18n } from 'vue-i18n';
    import { passwordRequirements } from '@/common/validation/password';
    import PasswordRequirements from '@/common/components/PasswordRequirements.vue';

    const emits = defineEmits<{
        submit: [passwordUpdateCredentials: PasswordUpdateCredentials];
        close: [];
    }>();

    const { t } = useI18n();
    const { lengthCheck, numberCheck, letterCheck, validatorFn } = passwordRequirements();

    const schema = object({
        oldPassword: string(),
        newPassword: string()
            .trim()
            .refine(validatorFn, {
                message: t('FORM.PASSWORD_VALIDATION.ERROR'),
            }),
        newPasswordConfirm: string().refine(areSamePassword, {
            message: t('FORM.PASSWORD_CONFIRM_VALIDATION_TEXT'),
        }),
    });

    const { handleSubmit, errors, defineField } = useForm({
        validationSchema: toTypedSchema(schema),
    });

    const [oldPassword] = defineField('oldPassword');
    const [newPassword] = defineField('newPassword');
    const [newPasswordConfirm] = defineField('newPasswordConfirm');

    const onSubmit = handleSubmit((values) => {
        emits('submit', {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
        });
    });

    function areSamePassword(value: string): boolean {
        return newPassword.value === value;
    }
</script>

<template>
    <Dialog :visible="true" modal @update:visible="emits('close')">
        <template #header
            ><div class="dialog-header-typography">{{ t('ACCOUNT.CHANGE_PASSWORD') }}</div></template
        >
        <form class="flex-container" @submit.prevent="onSubmit">
            <FormField :label="t('FORM.OLD_PASSWORD')" :error-message="errors.oldPassword">
                <Password
                    v-model="oldPassword"
                    class="full-width"
                    :input-props="{
                        // container div and input itself need full width styles (primevue)
                        class: 'full-width',
                    }"
                    :feedback="false"
                    :invalid="hasValidationError(errors.oldPassword)"
                />
            </FormField>
            <FormField :label="t('FORM.NEW_PASSWORD')" :error-message="errors.newPassword">
                <Password
                    v-model="newPassword"
                    class="full-width"
                    :input-props="{
                        // container div and input itself need full width styles (primevue)
                        class: 'full-width',
                    }"
                    :feedback="false"
                    :invalid="hasValidationError(errors.newPassword)"
                />
            </FormField>
            <PasswordRequirements
                :letter-check="letterCheck"
                :number-check="numberCheck"
                :length-check="lengthCheck"
            ></PasswordRequirements>
            <FormField :label="t('FORM.PASSWORD_CONFIRM')" :error-message="errors.newPasswordConfirm">
                <Password
                    v-model="newPasswordConfirm"
                    class="full-width"
                    :input-props="{
                        // container div and input itself need full width styles (primevue)
                        class: 'full-width',
                    }"
                    :feedback="false"
                    :invalid="hasValidationError(errors.newPasswordConfirm)"
                />
            </FormField>
        </form>
        <br />
        <template #footer>
            <Button outlined type="button" :label="t('COMMON.CANCEL')" @click="emits('close')" />
            <Button type="submit" :label="t('COMMON.SAVE')" @click="onSubmit" />
        </template>
    </Dialog>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .flex-container {
        display: flex;
        flex-direction: column;
        width: min(60ch, 90vw);
    }
</style>
