<script setup lang="ts">
    import { useCompanyAction } from '@/composables/company';
    import { useAuthenticationAction, useAuthenticationQuery } from '@/composables/authentication';
    import AddressFormDialog from '@/account/dialogs/AddressFormDialog.vue';
    import EditPersonalDataDialog from '@/account/dialogs/EditPersonalDataDialog.vue';
    import PasswordUpdateDialog from '@/account/dialogs/PasswordUpdateDialog.vue';
    import type { AddressDto, CompanyDto } from '@containex/portal-backend-dto';
    import Button from 'primevue/button';
    import Divider from 'primevue/divider';
    import Message from 'primevue/message';
    import Panel from 'primevue/panel';
    import { HttpStatusCode } from 'axios';
    import useGlobalToast from '@/composables/useGlobalToast';
    import { useErrorHandler } from '@/composables/useErrorHandler';
    import type { PasswordUpdateCredentials } from '@/model/password-update-credentials';
    import type { CustomerUpdateData } from '@/account/model/customer-update';
    import { useCustomerAction } from '@/composables/customer';
    import { useAsyncTask } from 'vue-concurrency';
    import { computed, ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import AddressDisplay from '@/components/AddressDisplay.vue';
    import PersonalData from '@/account/components/PersonalData.vue';
    import { useMarketQuery } from '@/composables/market';
    import { isMobile } from '@/util/breakpoints';

    const props = defineProps<{
        company?: CompanyDto;
    }>();

    const companyAction = useCompanyAction();
    const customerAction = useCustomerAction();
    const authenticationAction = useAuthenticationAction();
    const { currentCustomer, isAdmin } = useAuthenticationQuery();
    const { errorToastOptions, addToast } = useGlobalToast();
    const { t } = useI18n();
    const { market } = useMarketQuery();

    const hasPendingUpdates = computed(() => props.company?.updatePending ?? false);
    const companyNumber = computed(() => props.company?.externalId ?? props.company?.id);
    const uid = computed(() => {
        const uids = props.company?.uids ?? {};
        const code = market.value?.code.toLowerCase();
        const entry = Object.entries(uids).find(([key]) => key.toLowerCase() === code);

        return entry?.[1];
    });
    const showEditPersonalDataDialog = ref(false);
    const showPasswordUpdateDialog = ref(false);
    const showAddressFormDialog = ref(false);

    const errorHandler = useErrorHandler().errorHandlerFrom({
        name: 'AccountSettingViewContent',
        statusCodeHandlers: {
            [HttpStatusCode.BadRequest]() {
                addToast({
                    ...errorToastOptions,
                    summary: 'ERROR.CHANGE_COMPANY_BAD_REQUEST.SUMMARY',
                    detail: 'ERROR.CHANGE_COMPANY_BAD_REQUEST.DETAIL',
                });
            },
            [HttpStatusCode.Unauthorized]() {
                addToast({
                    ...errorToastOptions,
                    summary: 'ERROR.CHANGE_COMPANY_UNAUTHORIZED.SUMMARY',
                    detail: 'ERROR.CHANGE_COMPANY_UNAUTHORIZED.DETAIL',
                });
            },
            [HttpStatusCode.NotFound]() {
                addToast({
                    ...errorToastOptions,
                    summary: 'ERROR.COMPANY_NOT_FOUND.SUMMARY',
                    detail: 'ERROR.COMPANY_NOT_FOUND.DETAIL',
                });
            },
        },
    });

    const updateUserData = useAsyncTask(async (signal, data: CustomerUpdateData) => {
        await errorHandler.handle(async () => {
            if (currentCustomer.value == null || props.company == null) {
                return;
            }

            await customerAction.updateCustomer(currentCustomer.value.id, {
                email: data.email,
                firstName: data.first_name,
                lastName: data.last_name,
                jobTitle: data.job_title,
                phoneBusiness: data.phone_business,
                phoneMobile: data.phone_mobile,
                roles: data.roles,
                salutation: data.salutation,
                language: props.company.language,
            });
        });

        showEditPersonalDataDialog.value = false;
    });

    async function updatePassword(credentials: PasswordUpdateCredentials): Promise<void> {
        await errorHandler.handle(async () => {
            const response = await authenticationAction.updatePassword(
                credentials.oldPassword,
                credentials.newPassword
            );

            if (response.status !== HttpStatusCode.Ok || response.data == null) {
                showErrorToastGeneral();
            } else {
                showSuccessToastForChange();
            }
        });

        showPasswordUpdateDialog.value = false;
    }

    async function updateDeliveryAddress(address: AddressDto): Promise<void> {
        await errorHandler.handle(async () => {
            const response = await companyAction.updateCompany({
                deliveryAddress: address,
            });

            if (response.status !== HttpStatusCode.Ok || response.data == null) {
                showErrorToastGeneral();
            } else {
                showSuccessToastForChange();
            }
        });

        showAddressFormDialog.value = false;
    }

    function showErrorToastGeneral(): void {
        addToast(errorToastOptions);
    }

    function showSuccessToastForChange(): void {
        addToast({
            ...errorToastOptions,
            severity: 'success',
            summary: 'COMPANY.TOAST.CHANGE_COMPANY.SUMMARY',
            detail: 'COMPANY.TOAST.CHANGE_COMPANY.DETAIL',
        });
    }
</script>

<template>
    <Message v-if="hasPendingUpdates" :closable="false">{{ t('ACCOUNT.UPDATE_PENDING_INFO') }}</Message>

    <h2 class="text-2xl-bold-line-height-auto">{{ t('ACCOUNT.MY_ACCOUNT') }}</h2>

    <Panel>
        <template #header>
            <h3 class="text-xl-bold-line-height-auto">{{ t('ACCOUNT.PERSONAL_DATA') }}</h3>
        </template>
        <div class="content" :class="{ 'content-mobile': isMobile }">
            <h4 class="text-base-regular-line-height-auto subheader" :class="{ 'grid-full-row': isMobile }">
                {{ t('ACCOUNT.GENERAL') }}
            </h4>
            <PersonalData v-if="currentCustomer != null" :customer="currentCustomer"></PersonalData>
            <Button
                text
                icon="pi pi-pencil"
                class="edit-button"
                :label="t('COMMON.EDIT')"
                @click="showEditPersonalDataDialog = true"
            />
            <Divider class="divider"></Divider>

            <h4 class="text-base-regular-line-height-auto subheader" :class="{ 'grid-full-row': isMobile }">
                {{ t('ACCOUNT.EMAIL') }}
            </h4>
            <div :class="{ 'grid-full-row': isMobile }">{{ currentCustomer?.email }}</div>
            <Divider class="divider"></Divider>

            <h4 class="text-base-regular-line-height-auto subheader" :class="{ 'grid-full-row': isMobile }">
                {{ t('ACCOUNT.PASSWORD') }}
            </h4>
            <div>&lowast;&lowast;&lowast;&lowast;&lowast;&lowast;&lowast;&lowast;</div>
            <Button
                text
                icon="pi pi-pencil"
                class="edit-button"
                :label="t('ACCOUNT.CHANGE_PASSWORD')"
                @click="showPasswordUpdateDialog = true"
            />
        </div>
    </Panel>

    <Panel>
        <template #header>
            <h2 class="text-xl-bold-line-height-auto">{{ t('ACCOUNT.COMPANY_DATA') }}</h2>
        </template>
        <div class="content" :class="{ 'content-mobile': isMobile }">
            <h4 class="text-base-regular-line-height-auto subheader" :class="{ 'grid-full-row': isMobile }">
                {{ t('ACCOUNT.COMPANY_NUMBER') }}
            </h4>
            <div>{{ companyNumber }}</div>
            <Divider class="divider"></Divider>

            <h4 class="text-base-regular-line-height-auto subheader" :class="{ 'grid-full-row': isMobile }">
                {{ t('ACCOUNT.GENERAL') }}
            </h4>
            <div :class="{ 'grid-full-row': isMobile }">
                <div>{{ company?.companyName }}</div>
                <div>{{ t('ACCOUNT.COMPANY_UID') }}: {{ uid }}</div>
            </div>
            <Divider class="divider"></Divider>

            <h4 class="text-base-regular-line-height-auto subheader" :class="{ 'grid-full-row': isMobile }">
                {{ t('ACCOUNT.COMPANY_ADDRESS') }}
            </h4>
            <div>
                <AddressDisplay
                    v-if="company?.companyAddress != null"
                    :class="{ 'grid-full-row': isMobile }"
                    :address="company.companyAddress"
                ></AddressDisplay>
                <div>{{ company?.deliveryAddress.phone }}</div>
            </div>
            <Divider class="divider"></Divider>

            <h4 class="text-base-regular-line-height-auto subheader" :class="{ 'grid-full-row': isMobile }">
                {{ t('ACCOUNT.SHIPPING_ADDRESS') }}
            </h4>
            <div>
                <div>{{ company?.companyName }}</div>
                <div>{{ company?.deliveryAddress.firstName }} {{ company?.deliveryAddress.lastName }}</div>
                <AddressDisplay
                    v-if="company?.deliveryAddress != null"
                    :address="company.deliveryAddress"
                ></AddressDisplay>
                <div>{{ company?.deliveryAddress.phone }}</div>
            </div>
            <Button
                :disabled="!isAdmin"
                text
                icon="pi pi-pencil"
                class="edit-button"
                :label="t('COMMON.EDIT')"
                @click="showAddressFormDialog = true"
            />
            <Divider class="divider"></Divider>

            <h4 class="text-base-regular-line-height-auto subheader" :class="{ 'grid-full-row': isMobile }">
                {{ t('ACCOUNT.BILLING_ADDRESS') }}
            </h4>
            <div>
                <div>{{ company?.companyName }}</div>
                <AddressDisplay
                    v-if="company?.billingAddress != null"
                    :address="company.billingAddress"
                ></AddressDisplay>
                <div>{{ company?.billingAddress.phone }}</div>
            </div>
        </div>
    </Panel>

    <EditPersonalDataDialog
        v-if="showEditPersonalDataDialog && currentCustomer != null"
        :customer="currentCustomer"
        @submit="updateUserData.perform($event)"
        @close="showEditPersonalDataDialog = false"
    />
    <PasswordUpdateDialog
        v-if="showPasswordUpdateDialog"
        @submit="updatePassword"
        @close="showPasswordUpdateDialog = false"
    />
    <AddressFormDialog
        v-if="company?.deliveryAddress != null && showAddressFormDialog"
        :address="company.deliveryAddress"
        :title="t('ACCOUNT.EDIT_SHIPPING_ADDRESS')"
        @submit="updateDeliveryAddress"
        @close="showAddressFormDialog = false"
    />
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    :deep(.p-panel-header) {
        background: unset;
    }

    .content {
        display: grid;
        grid-template-columns: minmax(min-content, 1fr) 3fr auto;
        align-items: center;
        gap: 1ch;
        container-type: inline-size;
    }

    .content-mobile {
        grid-template-columns: minmax(min-content, 1fr) auto;
    }

    .divider {
        grid-column: 1 / -1;
    }

    .subheader {
        color: main.$textSecondaryColor;
    }

    .edit-button {
        justify-self: end;
    }

    .grid-full-row {
        grid-column: 1 / -1;
    }

    @container (max-width: 700px) {
        .edit-button :deep(.p-button-label) {
            display: none;
        }
    }
</style>
